import React from 'react';
import PageLayout from '../../layouts/page';

import './index.scss';

const About = () => {
  return (
    <PageLayout
      title="About AIVion"
    >
      <h2 className="about__subheader">Who is AIVion</h2>
      <p className="about__content">
        AIVion LLC currently serves US Navy offices located at the Naval Surface Warfare Center,
        Dahlgren, Virginia, providing modeling and simulation, software programming, and data
        analysis support. We maintain large-scale simulations as well as design and develop special
        purpose models and tools geared toward supporting warfare analysts. AIVion LLC is a
        Veteran-Owned Small Business established in 2021 in Fredericksburg, Virginia.
      </p>
      <h2 className="about__subheader">Why AIVion</h2>
      <ul>
        <li>
          Over twenty years of experience supporting models and simulation at Dahlgren.
        </li>
        <li>
          Multiple programming languages and development tools.
        </li>
        <li>
          Low-cost solutions.
        </li>
      </ul>
      <h2 className="about__subheader">Company Data</h2>
      <p className="about__content">
        We are fully recognized by the U.S. Small Business Administration (SBA) and the Virginia
        State Corporation Commission to do business with the Federal Government and State of
        Virginia.
      </p>
      <ul>
        <li>
          DUNS Number: 118285952
        </li>
        <li>
          Socio-economic certifications: Veteran-owned
        </li>
        <li>
          CAGE Code: 96D35
        </li>
        <li>
          EIN: 87-2428657
        </li>
        <li>
          NAICS Codes: 541511
        </li>
        <li>
          SAM Registration: Active
        </li>
        <li>
          Accepting Credit Cards: Yes
        </li>
        <li>
          Business Type: Veteran-owned Small Business
        </li>
        <li>
          Fredericksburg City Business License: 28007
        </li>
        <li>
          LLC Established in Virginia, 2021
        </li>
      </ul>
    </PageLayout>
  );
}
 
export default About;
