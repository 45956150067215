// Screen sizes
export const desktop = 1200;
export const laptop = 992;
export const tablet = 768;
export const mobile = 576;

export const headerHeight = 64;
export const mobileHeaderHeight = 56;
export const footerHeight = 60;

// Colors
export const primary = '#051622';
export const secondary = '#b37d4e';
export const tertiary = '#b3b3b3';

export const error = '#ea0000';

const variables = {
  desktop,
  laptop,
  tablet,
  mobile,
  headerHeight,
  mobileHeaderHeight,
  footerHeight,
  primary,
  secondary,
  tertiary,
  error,
}

export default variables;
