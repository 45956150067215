import React from 'react';
import PageLayout from '../../layouts/page';

import './index.scss';

const Capabilities = () => {
  return (
    <PageLayout
      title="Capabilities"
    >
      <h2 className="capabilities__subheader">Modeling and Simulation</h2>
      <p className="capabilities__content">
        AIVion has over twenty years of analytical modeling and simulation experience in mission-level
        air and surface warfare. Our expertise spans a variety of modeling techniques, scientific
        computing, and analytical methods. From maintaining large-scale simulations to developing
        single-purpose tools and visualizations, AIVion has the knowledge base to offer customers
        innovative solutions to their modeling and simulation needs.
      </p>
      <h2 className="capabilities__subheader">Software Development</h2>
      <p className="capabilities__content">
        AIVion has been developing high-quality software applications for the US Navy for over twenty
        years and have extensive experience in a variety programming languages and software libraries.
        With special expertise in scientific computing, we have developed robust solutions to numerical
        problems in languages including C/C++, modern Fortran, MATLAB, and the Python scientific stack.
        AIVion personnel are SCRUM-master certified with thorough knowledge of source code control and
        the Atlassian suite of tools: Jira, Confluence and Bitbucket. We can establish a tailored
        development environment or smoothly transition into an existing one.
      </p>
      <h2 className="capabilities__subheader">Verification and Validation</h2>
      <p className="capabilities__content">
        A basic principle of modeling and simulation is that development and V&V are intrinsically
        interwoven throughout the model's lifetime. Verification - "did you build the thing right" - and
        validation - "did you build the right thing" - provide a measure of confidence that the
        conclusions drawn from the model will be close to the actual system being modeled. We have
        demonstrated experience in the V&V process from development of modeling and simulation plans and
        procedures to execution to final reporting.
      </p>
    </PageLayout>
  );
}
 
export default Capabilities;
