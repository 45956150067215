import React from 'react';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Drawer,
} from '@material-ui/core';

import './index.scss';

const MobileMenu = ({ isOpen, toggleMenu }) => (
  <Drawer
    anchor="left"
    open={isOpen}
    onClose={toggleMenu}
    className="mobile-menu"
  >
    <div className="mobile-menu__menu-button-container">
      <Button className="mobile-menu__menu-button" onClick={toggleMenu}>
        <NavLink to="/capabilities" className="mobile-menu__menu-button-link">
          Capabilities
        </NavLink>
      </Button>
      <Button className="mobile-menu__menu-button" onClick={toggleMenu}>
        <NavLink to="/customers" className="mobile-menu__menu-button-link">
          Customers
        </NavLink>
      </Button>
      <Button className="mobile-menu__menu-button" onClick={toggleMenu}>
        <NavLink to="/about" className="mobile-menu__menu-button-link">
          About AIVion
        </NavLink>
      </Button>
      <Button className="mobile-menu__menu-button" onClick={toggleMenu}>
        <NavLink to="/contact" className="mobile-menu__menu-button-link">
          Contact Us
        </NavLink>
      </Button>
    </div>
  </Drawer>
);
 
export default MobileMenu;
