import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import MobileMenu from '../mobileMenu';

import {
  AppBar,
  Button,
  IconButton,
  Toolbar,
} from '@material-ui/core';
import { Menu } from '@material-ui/icons';

import { screenSizes } from '../../constants';

import logo from '../../images/aivion-logo.jpg';

import './index.scss';

const Navbar = ({ windowSize }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(prevState => !prevState)
  }

  return (
    <AppBar position="fixed" className="app-bar__container">
      <Toolbar className="app-bar">
        <div className="app-bar__personal">
          <Link to="/">
            <img src={logo} alt="AIVion LLC" className="app-bar__logo" />
          </Link>
        </div>
        {windowSize.width < screenSizes.tablet && (
          <>
            <IconButton className="app-bar__mobile-menu-icon" onClick={toggleMobileMenu}>
              <Menu />
            </IconButton>
            <MobileMenu
              isOpen={mobileMenuOpen}
              toggleMenu={toggleMobileMenu}
            />
          </>
        )}
        {windowSize.width >= screenSizes.tablet && (
          <div className="app-bar__menu-button-container">
            <Button className="app-bar__menu-button">
              <Link to="/capabilities" className="app-bar__menu-button-link">
                Capabilities
              </Link>
            </Button>
            <Button className="app-bar__menu-button">
              <Link to="/customers" className="app-bar__menu-button-link">
                Customers
              </Link>
            </Button>
            <Button className="app-bar__menu-button">
              <Link to="/about" className="app-bar__menu-button-link">
                About AIVion
              </Link>
            </Button>
            <Button className="app-bar__menu-button">
              <Link to="/contact" className="app-bar__menu-button-link">
                Contact Us
              </Link>
            </Button>
          </div>
        )}
      </Toolbar>
    </AppBar>
  )
}

const mapStateToProps = (state) => ({
  windowSize: state.windowSize
});

export default connect(mapStateToProps)(Navbar);