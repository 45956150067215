import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Navbar from '../components/navbar';
import Footer from '../components/footer';

import { updateWindowSize } from '../actions/windowSize';

import { footerHeight, headerHeight } from '../styles/variables.js'
import './page.scss';

const PageLayout = ({
  title,
  children,
  noTopMargin,
  windowSize,
  onWindowResize,
  additionalClasses
}) => {
  useEffect(() => {
    const handleResize = () => {
      onWindowResize(window.innerHeight, window.innerWidth)
    }

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize)
  }, [onWindowResize]);

  const style = {
    height: windowSize.height - headerHeight - footerHeight - 45,
    marginTop: noTopMargin ? 0 : headerHeight,
    overflow: 'auto'
  };

  return (
    <>
      <Navbar />
      <div className={`page-container ${additionalClasses}`} style={style}>
        {title && (
          <h1 className="page-title">
            {title}
          </h1>
        )}
        {children}
      </div>
      <Footer />
    </>
  );
}

const mapStateToProps = (state) => ({
  windowSize: state.windowSize
})

const mapDispatchToProps = {
  onWindowResize: (height, width) => updateWindowSize(height, width)
}
 
export default connect(mapStateToProps, mapDispatchToProps)(PageLayout);