import { createTheme } from '@material-ui/core/styles';
import variables from './styles/variables';

const theme = createTheme({
  palette: {
    primary: {
      main: variables.primary
    },
    secondary: {
      main: variables.secondary
    },
    tertiary: {
      main: variables.tertiary
    },
    error: {
      main: variables.error
    }
  },
  typography: {
    fontFamily: [
      "'Lucida Sans'",
      "'Lucida Sans Regular'",
      "'Lucida Grande'",
      "'Lucida Sans Unicode'",
      "Geneva",
      "Verdana",
      "sans-serif"
    ].join(','),
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: variables.mobile,
      md: variables.tablet,
      lg: variables.laptop,
      xl: variables.desktop,
    }
  }
})

export default theme;