import {
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import { ThemeProvider } from '@material-ui/core';

import Capabilities from './pages/capabilities';
import Customers from './pages/customers';
import About from './pages/about';
import Contact from './pages/contact';
import Home from "./pages/home";
import PageNotFound from "./pages/404";

import ScrollToTop from './helpers/scrollToTop';

import theme from './theme';

// import './App.scss';
// import { AuthProvider } from './auth';

const App = () => (
  <ThemeProvider theme={theme}>
    <div className="App">
      {/* <AuthProvider> */}
        <Router>
          <ScrollToTop />
          <Routes>
            <Route
              path="/capabilities"
              element={<Capabilities />}
            />
            <Route
              path="/customers"
              element={<Customers />}
            />
            <Route
              path="/about"
              element={<About />}
            />
            <Route
              path="/contact"
              element={<Contact />}
            />
            <Route
              path="/"
              element={<Home />}
            />
            <Route
              path="*"
              element={<PageNotFound />}
            />
          </Routes>
        </Router>
      {/* </AuthProvider> */}
    </div>
  </ThemeProvider>
);

export default App;
