import React from 'react';
import PageLayout from '../../layouts/page';

import './index.scss';

const Home = () => {
  return (
    <PageLayout
      title="AIVion - Your portal to innovative solutions."
    >
      <h3 className="home__subheader">
        Achieving better, cost effective decision-making through modeling and simulation.
      </h3>
      <h2 className="home__subheader">Our Mission</h2>
      <p className="home__content">
        We primarily provide expertise in analysis, design, and development of models and simulations
        supporting naval warfare analysis. Applications developed and/or maintained range from special-purpose
        analytical tools to large-scale anti-air warfare simulations. Core capabilities include object-oriented
        design and development, data modeling and analysis, and software verification and validation.
        Programming languages include C++, modern Fortran, MATLAB, and the Python scientific stack. Our
        personnel are Scrum Master can integrate seamlessly into development teams Scrum Master certified
        and experienced with Git source code control and the Altassian software development and collaboration
        tools.
      </p>
      <p className="home__content">
        AIVion seeks to provide NSWCDD scientists and engineers with high-quality programming support in the
        areas of modeling and simulation, scientific computing, and data analysis.
      </p>
    </PageLayout>
  );
}
 
export default Home;
