const defaultState = {
  height: 0,
  width: 0,
}

const windowSizeReducer = (state = defaultState, action) => {    
  switch(action.type) {
    case 'UPDATE_WINDOW_SIZE':
      return {
        height: action.height,
        width: action.width,
      };
    default:
      return state;
  }
}

export default windowSizeReducer;