import React from 'react'
import { Grid } from '@material-ui/core';

import './index.scss';

const PageFooter = () => {
  return (
    <div id="footer">
      <Grid container className="footer__container">
        <Grid item xs={12} className="footer__copyright">
          ©2021 by AIVion LLC
        </Grid>
      </Grid>
    </div>
  );
}
 
export default PageFooter;