import React from 'react';
import PageLayout from '../../layouts/page';

import './index.scss';

const Customers = () => {
  return (
    <PageLayout
      title="Customers"
    >
      <p className="customers__content">
        We currently support the Naval Surface Warfare Center, Dahlgren Division (NSWCDD) Modeling
        and Simulation Tool Development Branch (Code M31).
      </p>
    </PageLayout>
  );
}
 
export default Customers;
