import React from 'react';
import PageLayout from '../../layouts/page';

import './index.scss';

const email = 'info@aivionllc.com';

const Contact = () => {
  return (
    <PageLayout
      title="Contact Us"
    >
      <p className="contact__content">
        We appreciate your interest in AIVion. You can reach us through the following means.
      </p>
      <div className="contact__info">
        <p>
          <strong>By phone: </strong><a href="tel:5406215765">(540) 621-5765</a>
        </p>
        <p>
          <strong>By email: </strong><a href={`mailto:${email}`}>{email}</a>
        </p>
        <p>
          <strong>Address: </strong>1115 Great Oaks Ln, Fredericksburg, VA 22401
        </p>
      </div>
    </PageLayout>
  );
}
 
export default Contact;