import React from 'react';
import PageLayout from '../../layouts/page';

import './index.scss';

const PageNotFound = () => {
  return (
    <PageLayout
      title="Page Not Found"
    >
      <h3>
        We can't seem to find the page you're looking for.
      </h3>
      <p>Were you looking for one of these?</p>
      <ul>
        <li>
          <a href="/capabilities">Capabilities</a>
        </li>
        <li>
          <a href="/customers">Customers</a>
        </li>
        <li>
          <a href="/about">About AIVion</a>
        </li>
        <li>
          <a href="/contact">Contact Us</a>
        </li>
      </ul>
      <p>
        <a href="/">No thanks, just take me home</a>
      </p>
    </PageLayout>
  );
}
 
export default PageNotFound;
